<template>
  <v-row>
    <v-col cols="6" lg="4">
      <v-switch v-model="obPriceList.active" :label="$t('active')"></v-switch>
    </v-col>

    <v-col cols="6" lg="4">
      <v-switch
        v-model="obPriceList.is_default"
        :disabled="fixDefault"
        :label="$t('default')"
      ></v-switch>
    </v-col>

    <v-col cols="6" lg="4">
      <v-switch
        v-model="obPriceList.price_with_tax"
        :label="$t('price.with.tax')"
      ></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obPriceList.name" required />
    </v-col>

    <v-col cols="12" lg="6" xl="2">
      <code-field-text
        v-model="obPriceList.code"
        :show-code-button="!obPriceList.code"
        item-key="pricelist"
        label="code"
        required
      />
    </v-col>

    <v-col cols="12" lg="6" xl="3">
      <price-list-select
        v-model="obPriceList.price_list_id"
        :disabled-id="[obPriceList.id]"
      />
    </v-col>

    <v-col cols="12" lg="6" xl="3">
      <form-field-text
        v-model="obPriceList.percent"
        :disabled="!obPriceList.price_list_id"
        label="percent"
        type="number"
      />
    </v-col>

    <v-col v-if="obPriceList.id" cols="12" class="text-center">
      <v-btn color="primary" @click="onToggleUpdatePrices">
        {{ $t("update.prices") }}
      </v-btn>
    </v-col>
    <v-col v-if="updatePrices" cols="12">
      <price-list-table :price-list="obPriceList" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import DataTableMixin from "@/mixins/DataTableMixin";
import { PriceList } from "@planetadeleste/vue-mc-gw";
import OfferRow from "@/modules/pricelists/components/OfferRow.vue";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";
import PriceListSelect from "@/modules/pricelists/components/PriceListSelect.vue";
import PriceListTable from "@/modules/pricelists/components/PriceListTable.vue";

@Component({
  components: { PriceListTable, PriceListSelect, CodeFieldText, OfferRow },
})
export default class PricelistsSettings extends Mixins(DataTableMixin) {
  @VModel({ type: Object, default: () => new PriceList() })
  obPriceList!: PriceList;
  @Prop(Boolean) readonly fixDefault!: boolean;

  updatePrices = false;

  onToggleUpdatePrices() {
    this.updatePrices = !this.updatePrices;
  }
}
</script>
